.page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1em 1em;
}

.contact-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  font-family: Calibri;
  font-weight: 100;
  margin: 1em 1em;
}

.text {
  text-align: justify;
  font-family: Calibri;
  font-weight: 500;
  font-size: 1.2em;
  margin: 0;
}

.map-container {
  top: 0;
  left: 0;
  width: 100vw;
  height: 50vh;
  margin-bottom: 2em;
}
