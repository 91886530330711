.portfolio {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(22em, 1fr));
  grid-auto-rows: minmax(100px, auto);
  justify-items: center;
  align-items: center;
}

.portfolio-mobile {
  display: inline-block;
}

.tile-link {
  width: 100%;
  padding-left: 1px;
  padding-right: 1px;
  text-decoration: none;
}

.portfolio-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding-top: 3em;
  padding-bottom: 3em;

  width: 100%;
  max-width: 1300px;
}
