.about {
  margin-top: 3em;
  margin-bottom: 3em;
}

.about-text {
  margin: 2em;
  max-width: 40em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.about-img-mobile {
  max-width: 24em;
}

.about-img {
  width: 30em;
}
