.home {
  height: auto;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-content: center;
  -webkit-box-shadow: inset 0px -80px 30px -40px rgba(250, 250, 255, 1);
  -moz-box-shadow: inset 0px -80px 30px -40px rgba(250, 250, 255, 1);
  box-shadow: inset 0px -80px 30px -40px rgba(250, 250, 255, 1);
  overflow: hidden;
}
.background {
  width: auto;
}
