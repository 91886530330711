.header-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1300px;
}

.header-link-text {
  margin-left: 1em;
  margin-right: 1em;
  height: 1.75em;
  text-decoration: none;
  color: black;
  font-size: 12px;
  line-height: 21px;
  letter-spacing: 1px;
  font-weight: 500;
  transition: color 0.5s;
}

.header-link-text:hover {
  color: gray;
  border-bottom: solid 3px #148572;
  transition: 0.1s ease-in-out;
}

.header-link-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-big {
  margin: 0.5em;
  width: 28em;
}

.logo-small {
  margin-left: 2em;
}
