.footer {
  bottom: 0;
  width: 100%;
  background: #f5f5f5;
  height: 4em;

  display: flex;
  justify-content: center;
  align-items: center;
}

.social-icon {
  display: flex;
  justify-content: flex-start;
  width: 2.5em;
  height: 2.5em;
  margin: 0 1em;
}
