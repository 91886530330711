.offer-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-bottom: 6em;
  margin-top: 3em;
}

.offer {
  text-align: center;
}

.offer-link {
  text-decoration: none;
}
