.title {
  font-family: Calibri;
  font-size: 1.8em;
  font-weight: 900;
  letter-spacing: 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.line {
  width: 60%;
  height: 1px;
  border-top: 2px solid;
  border-image: radial-gradient(#fafaff, #148572, #fafaff) 1;
}
