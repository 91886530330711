.portfolio-tile-container {
  margin-top: 0.25em;
  display: block;
  height: 26em;
  width: 100%;
  overflow: hidden;
  position: relative;
  -webkit-filter: grayscale(1); /* Webkit Nightlies & Chrome Canary */
  -webkit-transition: all 0.5s ease-in-out;
  filter: gray; /* IE5+ */
  border-radius: 5px;
}

.portfolio-tile-container:hover {
  filter: none;
  -webkit-filter: grayscale(0);
}

.portfolio-tile {
  height: 100%;
  width: 100%;
  object-fit: cover;
  overflow: hidden;
  align-self: center;
}

.portfolio-tile-background {
  z-index: 1;
  position: relative;
  bottom: 60%;
  width: 100%;
  height: 50%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.portfolio-tile-description {
  font-family: Calibri;
  font-weight: 300;
  text-decoration: none;
  letter-spacing: 2px;
  margin-top: 1em;
  margin-bottom: 2em;
  color: rgba(255, 255, 255, 1);
}

.portfolio-tile-description-line {
  z-index: 2;
  width: 90%;
  border-bottom-width: 1px;
  border-top: 2px solid;
  border-image: radial-gradient(#000000, #fafaff, #000000) 1;
}
