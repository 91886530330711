.carousel .slide {
  padding: 1em 0;
  height: 80vh;
  background: transparent;
}

.carousel.carousel-slider{
  height: 100%;
}

.carousel.carousel-slider .control-arrow {
  color: black;
  background: black;
  top: 50%;
  height: 2em;
  width: 2em;
  border-radius: 1em;
}
