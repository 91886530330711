.header-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 1300px;
}

.header-link-text {
  margin-left: 1em;
  margin-right: 1em;
  height: 1.75em;
  text-decoration: none;
  color: black;
  font-size: 12px;
  line-height: 21px;
  letter-spacing: 1px;
  font-weight: 500;
  transition: color 0.5s;
}

.header-link-text:hover {
  color: gray;
  border-bottom: solid 3px #148572;
  transition: 0.1s ease-in-out;
}

.header-link-image {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logo-big {
  margin: 0.5em;
  width: 28em;
}

.logo-small {
  margin-left: 2em;
}

.footer {
  bottom: 0;
  width: 100%;
  background: #f5f5f5;
  height: 4em;

  display: flex;
  justify-content: center;
  align-items: center;
}

.social-icon {
  display: flex;
  justify-content: flex-start;
  width: 2.5em;
  height: 2.5em;
  margin: 0 1em;
}

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  -webkit-animation: spin 8s linear infinite;
  animation: spin 8s linear infinite;
}
@-webkit-keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.loading-spinner-img {
  width: 5em;
}

.portfolio {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(auto-fit, minmax(22em, 1fr));
  grid-auto-rows: minmax(100px, auto);
  justify-items: center;
  align-items: center;
}

.portfolio-mobile {
  display: inline-block;
}

.tile-link {
  width: 100%;
  padding-left: 1px;
  padding-right: 1px;
  text-decoration: none;
}

.portfolio-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding-top: 3em;
  padding-bottom: 3em;

  width: 100%;
  max-width: 1300px;
}

.portfolio-tile-container {
  margin-top: 0.25em;
  display: block;
  height: 26em;
  width: 100%;
  overflow: hidden;
  position: relative;
  -webkit-filter: grayscale(1); /* Webkit Nightlies & Chrome Canary */
  -webkit-transition: all 0.5s ease-in-out;
  -webkit-filter: gray;
          filter: gray; /* IE5+ */
  border-radius: 5px;
}

.portfolio-tile-container:hover {
  filter: none;
  -webkit-filter: grayscale(0);
}

.portfolio-tile {
  height: 100%;
  width: 100%;
  object-fit: cover;
  overflow: hidden;
  align-self: center;
}

.portfolio-tile-background {
  z-index: 1;
  position: relative;
  bottom: 60%;
  width: 100%;
  height: 50%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.portfolio-tile-description {
  font-family: Calibri;
  font-weight: 300;
  text-decoration: none;
  letter-spacing: 2px;
  margin-top: 1em;
  margin-bottom: 2em;
  color: rgba(255, 255, 255, 1);
}

.portfolio-tile-description-line {
  z-index: 2;
  width: 90%;
  border-bottom-width: 1px;
  border-top: 2px solid;
  border-image: radial-gradient(#000000, #fafaff, #000000) 1;
}

.page-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1em 1em;
}

.contact-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: center;
  font-family: Calibri;
  font-weight: 100;
  margin: 1em 1em;
}

.text {
  text-align: justify;
  font-family: Calibri;
  font-weight: 500;
  font-size: 1.2em;
  margin: 0;
}

.map-container {
  top: 0;
  left: 0;
  width: 100vw;
  height: 50vh;
  margin-bottom: 2em;
}

.carousel .slide {
  padding: 1em 0;
  height: 80vh;
  background: transparent;
}

.carousel.carousel-slider{
  height: 100%;
}

.carousel.carousel-slider .control-arrow {
  color: black;
  background: black;
  top: 50%;
  height: 2em;
  width: 2em;
  border-radius: 1em;
}

.home {
  height: auto;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-content: center;
  box-shadow: inset 0px -80px 30px -40px rgba(250, 250, 255, 1);
  overflow: hidden;
}
.background {
  width: auto;
}

.small-text {
    text-align: justify;
  font-family: Calibri;
  font-weight: 500;
  font-size: 1.2em;
}

.title {
  font-family: Calibri;
  font-size: 1.8em;
  font-weight: 900;
  letter-spacing: 1px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.line {
  width: 60%;
  height: 1px;
  border-top: 2px solid;
  border-image: radial-gradient(#fafaff, #148572, #fafaff) 1;
}

.offer-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-bottom: 6em;
  margin-top: 3em;
}

.offer {
  text-align: center;
}

.offer-link {
  text-decoration: none;
}

.shape {
  margin-bottom: 3em;
  width: 4em;
}

.space-mobile {
  width: 100vw;
  height: 4em;
}

.space {
  width: 100vw;
  height: 18em;
}

.about {
  margin-top: 3em;
  margin-bottom: 3em;
}

.about-text {
  margin: 2em;
  max-width: 40em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.about-img-mobile {
  max-width: 24em;
}

.about-img {
  width: 30em;
}

body {
  background-color: #fafaff;
  margin: 0;
  padding: 0;
  min-height: 100vh;
  overflow-x: hidden;
  overflow-y: scroll;
}

#top {
  width: 100%;
}

.link-image {
  margin-left: 1em;
  margin-right: 1em;
}

.app {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 100vh;
}

.landing {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: 10em;
}

.page-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1300px;
  height: 100%;
}

